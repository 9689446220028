import React, { useState, useEffect } from "react";
import { Nav, Tab } from "react-bootstrap";
import { useQuery } from "react-query";
import { useLocation } from "react-router";
import BeatLoader from "react-spinners/BeatLoader";
import { toast } from "react-toastify";
import { useStores } from "../../hooks/useStores";
import MainLayout from "../../layouts/main";
import BreakDown from "../Breakdown";
import Charts from "../Charts";
import DataDownload from "../DataDownload";
import KeyStats from "../KeyStats";
import LoadingModal from "../LoadingModal";
import SessionDetails from "../SessionDetails";
import styles from "./index.module.scss";

const Dashboard = () => {
  const search = useLocation().search;
  const clientCode = new URLSearchParams(search).get("i");
  const [isConsortia, setIsConsortia] = useState("");

  useEffect(() => {
    const isConsortiaPortal = new URLSearchParams(search).get("portal");
    const isConsortia5 = new URLSearchParams(search);
    setIsConsortia(isConsortiaPortal);
  }, []);

  const { report } = useStores();
  console.log("clientCode", clientCode);
  // This function is used to fetch session data
  const fetchSessionData = useQuery(
    ["fetchSessionData"],
    () =>
      report.listApiHandler({
        clientCode,
        queryParams: {
          stat: "session",
        },
      }),
    {
      onError: (err) => {
        // console.log(err.message);
        toast.error("Patient Code not found");
      },
    }
  );

  const renderDashboard = () => {
    return (
      <>
        <SessionDetails
          clientCode={clientCode}
          sessionData={fetchSessionData.data}
        ></SessionDetails>

        <div className="pt-4 row justify-content-md-center">
          <div className="col-xl-12">
            <div className={styles.bgColor}>
              <Tab.Container id="left-tabs-example" defaultActiveKey="charts">
                <Nav className={styles.navTabs}>
                  <Nav.Item className="col-12 col-sm-12 col-md-2 col-xl-2 p-0">
                    <Nav.Link eventKey="charts" className={styles.navLinks}>
                      Charts
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="col-12 col-sm-12 col-md-4 col-xl-4 p-0">
                    <Nav.Link
                      eventKey="alsfrsroads"
                      className={styles.navLinks}
                    >
                      ALSFRS / ROADS Breakdown
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="col-12 col-sm-12 col-md-3 col-xl-3 p-0">
                    <Nav.Link eventKey="keystats" className={styles.navLinks}>
                      Key Statistics
                    </Nav.Link>
                  </Nav.Item>
                  {console.log("isConsortia", isConsortia)}
                  {!isConsortia && (
                    <Nav.Item className="col-12 col-sm-12 col-md-3 col-xl-3 p-0">
                      <Nav.Link
                        eventKey="datadownload"
                        className={styles.navLinks}
                      >
                        Data Download
                      </Nav.Link>
                    </Nav.Item>
                  )}
                </Nav>
                <Tab.Content>
                  <Tab.Pane eventKey="keystats">
                    <KeyStats clientCode={clientCode}></KeyStats>
                  </Tab.Pane>
                  <Tab.Pane eventKey="charts">
                    <Charts
                      clientCode={clientCode}
                      sessionData={fetchSessionData.data}
                    ></Charts>
                  </Tab.Pane>
                  <Tab.Pane eventKey="alsfrsroads">
                    <BreakDown clientCode={clientCode}></BreakDown>
                  </Tab.Pane>
                  {!isConsortia && (
                    <Tab.Pane eventKey="datadownload">
                      <DataDownload clientCode={clientCode}></DataDownload>
                    </Tab.Pane>
                  )}
                </Tab.Content>
              </Tab.Container>
            </div>
          </div>
        </div>
      </>
    );
  };

  if (clientCode) {
    return (
      <MainLayout title="My Dashboard" clientCode={clientCode}>
        <BeatLoader loading={fetchSessionData.isFetching}></BeatLoader>
        {!fetchSessionData.isFetching && renderDashboard()}
      </MainLayout>
    );
  } else {
    return (
      <>
        <LoadingModal title="Patient Code is missing" />
      </>
    );
  }
};

export default Dashboard;
