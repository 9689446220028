import React from "react";
import BeatLoader from "react-spinners/BeatLoader";
const LoadingTableCell = ({ loading, children }) => {
  if (loading) {
    return (
      <td>
        <BeatLoader loading={loading}></BeatLoader>
      </td>
    );
  } else {
    return <td>{children}</td>;
  }
}
export default LoadingTableCell;
