import moment from "moment";
import React, { useEffect, useState } from "react";
import Calendar from "react-awesome-calendar";
import "./calender-custom.css";
import { Button, Col, Row } from "react-bootstrap";
import Box from "../../layouts/box";
import styles from "./index.module.scss";
// import SessionCalender from "../calender/SessionCalender";
import { useQuery } from "react-query";
import { useStores } from "../../hooks/useStores";
import BeatLoader from "react-spinners/BeatLoader";
import { roundToNearestMinutesWithOptions } from "date-fns/fp";

import Confetti from "react-confetti";

const TOTAL_WEEKS = 52;
const DATE_FORMAT = "Do MMMM YYYY - h:mm:ss a";

class SessionCalender extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: new Date(),
      calenderdata: [],
      events: [],
      loading: false,
      weekdays: [
        { name: "Sunday", back: 0, front: 6 },
        { name: "Monday", back: 1, front: 5 },
        { name: "Tuesday", back: 2, front: 4 },
        { name: "Wednesday", back: 3, front: 3 },
        { name: "Thursday", back: 4, front: 2 },
        { name: "Friday", back: 5, front: 1 },
        { name: "Saturday", back: 6, front: 0 },
      ],
      months: [
        { name: "Jan", num: 0 },
        { name: "Feb", num: 1 },
        { name: "Mar", num: 2 },
        { name: "Apr", num: 3 },
        { name: "May", num: 4 },
        { name: "Jun", num: 5 },
        { name: "Jul", num: 6 },
        { name: "Aug", num: 7 },
        { name: "Sep", num: 8 },
        { name: "Oct", num: 9 },
        { name: "Nov", num: 10 },
        { name: "Dec", num: 11 },
      ],
      current_month: 0,
      current_year: 0,
      days_in_month: 0,
      active1: false,
      active2: false,
      active3: false,
      active4: false,
      active5: false,
    };
    this.calendar = React.createRef();
  }
  componentDidMount() {
    let current_month = moment().format("MMM");
    for (let item of this.state.months) {
      if (item.name == current_month) {
        this.setState({
          current_month: item.num,
          current_year: moment().format("YYYY"),
        });
      }
    }
    let daysInMonth = moment().daysInMonth();
    this.setState({ days_in_month: daysInMonth }, () =>
      this.showcheckboxactive()
    );

    const details = this.calendar.current.getDetails();
    if (this.props) {
      this.setState({ calenderdata: this.props.data }, () => this.setEvents());
    }
    this.showcheckboxactive();
  }
  setEvents = () => {
    let data = this.state.calenderdata;
    let newArr = [];
    let obj = {};
    let count = 1;
    let duplicate = [];
    this.setState({ loading: true });
    if (this.state.calenderdata.length > 0) {
      let current_month = this.state.current_month;
      for (let item of this.state.calenderdata) {
        let selecteddate = item.date.split("T")[0];
        let monthname = moment(selecteddate).format("MMM");
        let current_year = moment(selecteddate).format("YYYY");

        let month = 0;
        for (let item of this.state.months) {
          if (item.name == monthname) {
            month = item.num;
          }
        }

        if (current_month == month && this.state.current_year == current_year) {
          // =================== Showing selected date ==============================

          let day = moment(selecteddate).format("DD");
          obj = {
            id: count,
            color: "",
            from: `${selecteddate}T00:00:00+00:00`,
            to: `${selecteddate}T00:00:00+00:00`,
            title: `${day}`,
          };
          let to = selecteddate;
          newArr = [...newArr, obj];

          // =================== Showing green line ==============================

          let dayname = moment(selecteddate).format("dddd");
          let back = 0;
          let front = 0;
          for (let item of this.state.weekdays) {
            if (item.name == dayname) {
              back = item.back;
              front = item.front;
            }
          }
          let addeddate = moment(selecteddate)
            .add(front, "days")
            .format("YYYY-MM-DD");
          let subtractdate = moment(selecteddate)
            .subtract(back, "days")
            .format("YYYY-MM-DD");

          let from_month_name = moment(subtractdate).format("MMM");
          let to_month_name = moment(addeddate).format("MMM");
          // if(from_month_name!=to_month_name && (selecteddate>='01' || selecteddate<='07')){

          // }else{
          let to_date = `${addeddate}T19:00:00+00:00`;
          if (!duplicate.includes(to_date)) {
            let obj2 = {
              id: count,
              color: "#6cd300",
              from: `${subtractdate}T18:00:00+00:00`,
              to: `${addeddate}T19:00:00+00:00`,
              title: "",
            };
            let from_date = `${subtractdate}T18:00:00+00:00`;
            newArr.push(obj2);
            duplicate.push(to_date);
          }
          // }
        }

        count++;
      }
      this.setState({ events: newArr, loading: false });
      setTimeout(() => {
        let eventtitle = document.querySelectorAll(".eventTitle");
        eventtitle.forEach((item, index) => {
          if (item.innerHTML != "") {
            item.classList.add("selected-date");
          }
        });
      }, 500);
    }
  };
  // show icons active
  showcheckboxactive = () => {
    let daysInMonth = this.state.days_in_month;
    let data = this.state.calenderdata;
    let current_month = this.state.current_month;

    let active1 = false;
    let active2 = false;
    let active3 = false;
    let active4 = false;
    let active5 = false;
    let active6 = false;

    for (let item of data) {
      let selecteddate = item.date.split("T")[0];
      let monthname = moment(selecteddate).format("MMM");
      let current_year = moment(selecteddate).format("YYYY");

      let month = 0;
      for (let item of this.state.months) {
        if (item.name == monthname) {
          month = item.num;
        }
      }

      if (current_month == month && this.state.current_year == current_year) {
        const d = new Date(selecteddate);
        const date = d.getDate();
        const day = d.getDay();
        const weekOfMonth = Math.ceil((date - 1 - day) / 7);

        if (weekOfMonth == "0") {
          active1 = true;
        } else if (weekOfMonth == "1") {
          active2 = true;
        } else if (weekOfMonth == "2") {
          active3 = true;
        } else if (weekOfMonth == "3") {
          active4 = true;
        } else if (weekOfMonth == "4") {
          active5 = true;
        }
      }
    }
    setTimeout(() => {
      this.setState({
        active1,
        active2,
        active3,
        active4,
        active5,
      });
    }, 1000);
  };

  test = (mJsDate) => {
    var str =
      mJsDate.toLocaleString().substring(0, 3) +
      " number " +
      Math.ceil(mJsDate.date() / 7) +
      " of the month";
    return str;
  };

  onChange = (params) => {
    let current_month = params.month;
    let current_year = params.year;
    this.setState(
      { current_month: current_month, current_year: current_year },
      () => this.setEvents()
    );
    /// setting days in month
    let dateval = params.year + "-" + (params.month + 1) + "-" + params.day;
    let daysInMonth = moment(dateval).daysInMonth();
    this.setState({ days_in_month: daysInMonth }, () =>
      this.showcheckboxactive()
    );
  };
  render() {
    return (
      <>
        <div className="calender-outer" style={{ height: "100%" }}>
          <div className="row" style={{ marginBottom: 25, paddingTop: 20 }}>
            <div
              className="col-xl-12 col-lg-12 d-flex calender-warpper"
              style={{ paddingLeft: 22 }}
            >
              <br />
              <div
                className="full-calender-wrapper-block"
                style={{ width: "90%" }}
              >
                <BeatLoader loading={this.state.loading}></BeatLoader>
                <Calendar
                  events={this.state.events}
                  ref={this.calendar}
                  onChange={this.onChange}
                />
              </div>
              <div className="active_sessions_of_weeks">
                <li>
                  <div
                    className={
                      this.state.active1 == true ? "circle active" : "circle"
                    }
                  >
                    <i className="bx bx-check"></i>
                  </div>
                  <div className="line"></div>
                </li>
                <li>
                  <div
                    className={
                      this.state.active2 == true ? "circle active" : "circle"
                    }
                  >
                    <i className="bx bx-check"></i>
                  </div>
                  <div className="line"></div>
                </li>
                <li>
                  <div
                    className={
                      this.state.active3 == true ? "circle active" : "circle"
                    }
                  >
                    <i className="bx bx-check"></i>
                  </div>
                  <div className="line"></div>
                </li>
                <li>
                  <div
                    className={
                      this.state.active4 == true ? "circle active" : "circle"
                    }
                  >
                    <i className="bx bx-check"></i>
                  </div>
                  <div className="line"></div>
                </li>
                <li>
                  <div
                    className={
                      this.state.active5 == true ? "circle active" : "circle"
                    }
                  >
                    <i className="bx bx-check"></i>
                  </div>
                </li>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const SessionDetails = ({ clientCode, sessionData }) => {
  const { report } = useStores();

  const [msg, setMsg] = useState();
  const [lastYearTotalSessions, setLastYearTotalSessions] = useState(0);
  const [totalSessions, setTotalSessions] = useState(0);
  const [progBar, setProgBar] = useState(0);
  const [calenderdata, setCalenderdata] = useState([]);
  const [loading, setLoading] = useState(false);
  const [run_status, setrun_status] = useState(true);
  const [no_of_pieces, setno_of_pieces] = useState(300);
  const [no_of_sessions, setNo_of_sessions] = useState(0);
  const [no_of_streaks, setNo_of_streaks] = useState(0);
  const [no_of_missing_sessions, setNo_of_missing_sessions] = useState(0);
  const [isVisible, setIsVisible] = useState(false);
  const [weekdays, setWeekdats] = useState([
    { name: "Sunday", back: 0, front: 6 },
    { name: "Monday", back: 1, front: 5 },
    { name: "Tuesday", back: 2, front: 4 },
    { name: "Wednesday", back: 3, front: 3 },
    { name: "Thursday", back: 4, front: 2 },
    { name: "Friday", back: 5, front: 1 },
    { name: "Saturday", back: 6, front: 0 },
  ]);
  const [secondweeksstatus, setSecondweeksstatus] = useState(false);
  const [maincount, setMaincount] = useState(0);
  const [missingNumber, setMissingNumber] = useState(0);

  useEffect(() => {
    if (sessionData) {
      setTotalSessions(sessionData?.TOTAL_SESSIONS);

      if (sessionData?.FIRST_SESSION_DATE) {
        let current = moment().startOf("day");
        let given = moment(sessionData?.FIRST_SESSION_DATE, "YYYY-MM-DD");
        let weeks = Math.trunc(moment.duration(current.diff(given)).asWeeks());

        setLastYearTotalSessions(weeks);
      }
    }
  }, [sessionData]);

  useEffect(() => {
    if (calenderdata.length > 0) {
      setIsVisible(true);
      console.log("calenderdata", calenderdata);
      setTimeout(() => {
        setno_of_pieces(0);
        setIsVisible(false);
      }, 10000);
    }
  }, [calenderdata]);

  useEffect(() => {
    function load() {
      //lastYearTotalSessions
      let frac = calculateProgress(totalSessions);
      setMsg(message(frac));
      setProgBar(frac * 100 + "%");
    }
    load();
  }, [lastYearTotalSessions]);

  const fetchSessionResponse = useQuery(
    "fetchSessionStats",
    () =>
      report.listApiHandler({
        clientCode,
        queryParams: { stat: "datadownload" },
      }),
    {
      onSuccess: (data) => {
        setLoading(true);
        if (data && data.results && data.results.length > 0) {
          setCalenderdata(data.results);
          // calculatemessagedata(data.results);

          setLoading(false);

          if (data && data.results && data.results.length > 0) {
            let arr = data.results.sort(
              (a, b) => new Date(b.date) - new Date(a.date)
            );

            // getWeeksInRow(arr);
            calculate_data_form_message(arr);

            let reverseArr = arr.reverse();
            calculate_missied_session(reverseArr);
          }
        }
      },
      onError: (err) => {},
    }
  );
  const formatDate2 = (date) => {
    return date;
  };
  const calculate_missied_session = (arr) => {
    //how many sessions the person missed
    let today = moment();
    let session_missing = 0;
    let missing_no = "";
    let difference = 0;
    difference = moment(today).diff(moment(arr[arr.length - 1].date), "days");
    missing_no = Number(difference) / 7;
    session_missing = Math.floor(missing_no);
    if (session_missing < 0) {
      session_missing = 0;
    }
    setMissingNumber(session_missing);
    console.log("missing_no", session_missing);
  };
  const calculate_data_form_message = (arr) => {
    //number of sessions
    let number_of_sessions = arr.length;
    setNo_of_sessions(number_of_sessions);

    //streak is done
    let streak = 0;
    let reverseArray = arr;
    reverseArray = arr.sort((a, b) => new Date(b.date) - new Date(a.date));
    // console.log('reverseArray',reverseArray);
    for (let i = 0; i < reverseArray.length; i++) {
      let gap = moment(reverseArray[i].date).diff(
        moment(reverseArray[i + 1].date),
        "days"
      );
      gap = Math.abs(gap);
      if (Number(gap) <= 7) {
        streak++;
      } else {
        break;
      }
    }
    setNo_of_streaks(streak);
    console.log("streak", streak);
  };

  // This function is used to calculate % progress
  const calculateProgress = (val) => {
    if (val > TOTAL_WEEKS) {
      return Math.round((100 * 100.0) / TOTAL_WEEKS) / 100;
    } else {
      return Math.round((val * 100.0) / TOTAL_WEEKS) / 100;
    }
  };
  const message = (frac) => {
    let message = "";
    if (frac === 0) message = "You need to start";
    else if (frac < 0.33) message = "You're less than 1/3 of the way there!";
    else if (frac === 0.33) message = "You're 1/3 of the way there!";
    else if (frac > 0.33 && frac < 0.67)
      message = "You're less than 2/3 of the way there!";
    else if (frac === 0.67) message = "You're 2/3 of the way there!";
    else if (frac > 0.67 && frac < 1)
      message = "You're more than 2/3 of the way there!";
    else if (frac === 1) message = "You're there!";

    return message;
  };

  const formatDate = (date) => {
    if (date) {
      let format_date = moment.utc(date).format(DATE_FORMAT);
      format_date = format_date + " PST";
      return format_date;
    } else return "";
  };

  const renderWeeksCompleted = () => {
    return (
      <Box>
        <div className="row row-padding">
          <div className="col-xl-12 col-lg-12" style={{ paddingLeft: 22 }}>
            <div className="py-3" style={{ paddingLeft: "21px !important" }}>
              <div className="fw-bolder" style={{ fontSize: 18 }}>
                Weeks Completed:
                {/* {lastYearTotalSessions > TOTAL_WEEKS ? lastYearTotalSessions : `${lastYearTotalSessions}/${TOTAL_WEEKS}`} */}
              </div>
              <div className="fst-italic" style={{ fontSize: 16 }}>
                {msg}
              </div>
            </div>
            <div
              className="progress rounded-pill position-relative"
              style={{ height: "30px" }}
            >
              <div
                className="progress-bar bg-light-green rounded-pill"
                role="progressbar"
                style={{ width: progBar }}
                aria-valuenow="75"
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
              <div className="position-absolute fw-bolder fs-5 end-10">
                {totalSessions > TOTAL_WEEKS
                  ? totalSessions
                  : `${totalSessions}/${TOTAL_WEEKS}`}
                {/* {lastYearTotalSessions}  */}
              </div>
            </div>
          </div>
          <div className="col-xl-12 col-lg-12">
            <a
              target="_blank"
              href="https://www.neuroserver.org/everything-als/trail/forms"
              className="text-white fw-bold fs-5 d-flex justify-content-center align-items-center btn btn-secondary"
              style={{
                width: "100%",
                position: "relative",
                left: 6,
                height: 100,
                margin: "24px auto 0 auto",
              }}
            >
              Update My Profile
            </a>
          </div>
        </div>
      </Box>
    );
  };
  const formatDate3 = (date) => {
    if (date) {
      let format_date = moment.utc(date).format("Do MMMM YYYY");
      format_date = format_date;
      return format_date;
    } else return "";
  };
  const renderSessionData = () => {
    const renderSessionBox = (title) => {
      return (
        <div style={{ fontSize: 16 }} className={`${styles.sessionBox} `}>
          {title}
        </div>
      );
    };
    return (
      <div className="mb-3">
        <Box>
          <Row className="row-padding">
            <Col lg={12} xl={12}>
              <div className="d-flex flex-column" style={{ fontWeight: "700" }}>
                {renderSessionBox(
                  `Date of Last Session: ${formatDate3(
                    sessionData?.LAST_SESSION_DATE
                  )}`
                )}
                {/* <div style={{marginTop:20}}>
                  {renderSessionBox(`Number of Sessions: ${totalSessions}`)}
                </div> */}
              </div>
            </Col>
            <Col lg={12} xl={12}>
              <Button
                variant="secondary"
                target="_blank"
                href={`https://webapi.modality.ai/avdialogue/prod/12/html/call.html?x=1230&i=${clientCode}`}
                className="w-100 text-white fw-bold fs-5 d-flex justify-content-center align-items-center"
                style={{
                  position: "relative",
                  height: 100,
                  margin: "24px auto 0 auto",
                }}
              >
                Start My Next Session
              </Button>
            </Col>
          </Row>
        </Box>
      </div>
    );
  };
  const renderStateData = () => {
    return (
      <div className="mb-3">
        <div className="state_data_section calender-outer">
          <div className="row">
            <div className="col-6 col-sm-6 col-md-6 col-lg-4 col-xl-4">
              <div className="active-days">
                <h4>{no_of_sessions}</h4>
                <span>Sessions</span>
              </div>
            </div>
            {/* <div className="col-lg-4"> */}
            {/* <div className="active-days"
                  style={{ paddingTop:5, }}
                >
                  <i style={{ color:'#6cd300' }} className='bx bx-up-arrow-alt'></i>
                  <span>
                    3 Days <br/>
                    vs. Last 30
                  </span>
                </div> */}
            {/* </div> */}
            <div className="col-6 col-sm-6 col-md-6 col-lg-4 col-xl-4">
              <div className="active-days">
                <h4>{no_of_streaks}</h4>
                <span>
                  Week
                  <br /> Streak
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const rendermessagedata = () => {
    if (calenderdata) {
      if (missingNumber > 10 && isVisible == true && calenderdata) {
        return (
          <div className="success_outer_wrapper animate__animated animate__backInDown animate__delay-2s">
            <div className="success_message_of_sessions">
              <h2>Ohh!</h2>
              <h4>
                Welcome back! We are so excited to see you back here! Let’s get
                started again in our fight against ALS!
              </h4>
            </div>
          </div>
        );
      } else if (
        missingNumber >= 5 &&
        missingNumber <= 10 &&
        isVisible == true &&
        calenderdata
      ) {
        return (
          <div className="success_outer_wrapper animate__animated animate__backInDown animate__delay-2s">
            <div className="success_message_of_sessions">
              <h2>Ohh!</h2>
              <h4>
                We missed you! We know life gets a little busy sometimes, but we
                hope you can continue to volunteer your voice to make a
                difference in the ALS community!{" "}
              </h4>
            </div>
          </div>
        );
      } else if (
        missingNumber >= 1 &&
        missingNumber <= 5 &&
        isVisible == true &&
        calenderdata
      ) {
        return (
          <div className="success_outer_wrapper animate__animated animate__backInDown animate__delay-2s">
            <div className="success_message_of_sessions">
              <h2>Ohh!</h2>
              <h4>Welcome Back to the Speech Study, we missed you!!!! </h4>
            </div>
          </div>
        );
      } else if (no_of_sessions == 52 && isVisible == true && calenderdata) {
        {
          /* if no of session is 1 */
        }
        return (
          <div className="success_outer_wrapper animate__animated animate__backInDown animate__delay-2s">
            <div className="success_message_of_sessions">
              <h2>Hurray!</h2>
              <h4>You did it!!! Thank you for all of your contributions!</h4>
            </div>
          </div>
        );
      } else if (no_of_sessions == 50 && isVisible == true && calenderdata) {
        {
          /* if no of session is 1 */
        }
        return (
          <div className="success_outer_wrapper animate__animated animate__backInDown animate__delay-2s">
            <div className="success_message_of_sessions">
              <h2>Wow!</h2>
              <h4>Just 2 more sessions till you cross that finish line!</h4>
            </div>
          </div>
        );
      } else if (no_of_sessions == 45 && isVisible == true && calenderdata) {
        {
          /* if no of session is 1 */
        }
        return (
          <div className="success_outer_wrapper animate__animated animate__backInDown animate__delay-2s">
            <div className="success_message_of_sessions">
              <h2>Great!</h2>
              <h4>The finish line is in sight!!!</h4>
            </div>
          </div>
        );
      } else if (no_of_sessions == 40 && isVisible == true && calenderdata) {
        {
          /* if no of session is 1 */
        }
        return (
          <div className="success_outer_wrapper animate__animated animate__backInDown animate__delay-2s">
            <div className="success_message_of_sessions">
              <h2>Congratulations!</h2>
              <h4>Getting so close! Just 12 more sessions!</h4>
            </div>
          </div>
        );
      } else if (no_of_sessions == 39 && isVisible == true && calenderdata) {
        {
          /* if no of session is 1 */
        }
        return (
          <div className="success_outer_wrapper animate__animated animate__backInDown animate__delay-2s">
            <div className="success_message_of_sessions">
              <h2>Congratulations!</h2>
              <h4>75% down, 25% to go! </h4>
            </div>
          </div>
        );
      } else if (no_of_sessions == 37 && isVisible == true && calenderdata) {
        {
          /* if no of session is 1 */
        }
        return (
          <div className="success_outer_wrapper animate__animated animate__backInDown animate__delay-2s">
            <div className="success_message_of_sessions">
              <h2>Congratulations!</h2>
              <h4>
                Your time is the best gift you could give the ALS community.{" "}
              </h4>
            </div>
          </div>
        );
      } else if (no_of_sessions == 33 && isVisible == true && calenderdata) {
        {
          /* if no of session is 1 */
        }
        return (
          <div className="success_outer_wrapper animate__animated animate__backInDown animate__delay-2s">
            <div className="success_message_of_sessions">
              <h2>Congratulations!</h2>
              <h4>Your voice is beautiful-</h4>
            </div>
          </div>
        );
      } else if (no_of_sessions == 30 && isVisible == true && calenderdata) {
        {
          /* if no of session is 1 */
        }
        return (
          <div className="success_outer_wrapper animate__animated animate__backInDown animate__delay-2s">
            <div className="success_message_of_sessions">
              <h2>Congratulations!</h2>
              <h4>We appreciate your vocal contributions!</h4>
            </div>
          </div>
        );
      } else if (no_of_sessions == 26 && isVisible == true && calenderdata) {
        {
          /* if no of session is 1 */
        }
        return (
          <div className="success_outer_wrapper animate__animated animate__backInDown animate__delay-2s">
            <div className="success_message_of_sessions">
              <h2>Congratulations!</h2>
              <h4>Half way there!</h4>
            </div>
          </div>
        );
      } else if (no_of_sessions == 25 && isVisible == true && calenderdata) {
        {
          /* if no of session is 1 */
        }
        return (
          <div className="success_outer_wrapper animate__animated animate__backInDown animate__delay-2s">
            <div className="success_message_of_sessions">
              <h2>Congratulations!</h2>
              <h4>Your help is inspiring- almost halfway done! </h4>
            </div>
          </div>
        );
      } else if (no_of_sessions == 20 && isVisible == true && calenderdata) {
        {
          /* if no of session is 1 */
        }
        return (
          <div className="success_outer_wrapper animate__animated animate__backInDown animate__delay-2s">
            <div className="success_message_of_sessions">
              <h2>Congratulations!</h2>
              <h4>Keep on keepin’ on!</h4>
            </div>
          </div>
        );
      } else if (no_of_sessions == 16 && isVisible == true && calenderdata) {
        {
          /* if no of session is 1 */
        }
        return (
          <div className="success_outer_wrapper animate__animated animate__backInDown animate__delay-2s">
            <div className="success_message_of_sessions">
              <h2>Congratulations!</h2>
              <h4>The ultimate</h4>
            </div>
          </div>
        );
      } else if (no_of_sessions == 13 && isVisible == true && calenderdata) {
        {
          /* if no of session is 1 */
        }
        return (
          <div className="success_outer_wrapper animate__animated animate__backInDown animate__delay-2s">
            <div className="success_message_of_sessions">
              <h2>Congratulations!</h2>
              <h4>¼ done!!! We believe in you, keep it up!</h4>
            </div>
          </div>
        );
      } else if (no_of_streaks == 13 && isVisible == true && calenderdata) {
        {
          /* if no of session is 1 */
        }
        return (
          <div className="success_outer_wrapper animate__animated animate__backInDown animate__delay-2s">
            <div className="success_message_of_sessions">
              <h2>Hurray!</h2>
              <h4>Precision and consistency are admirable traits of yours! </h4>
            </div>
          </div>
        );
      } else if (no_of_streaks == 10 && isVisible == true && calenderdata) {
        {
          /* if no of session is 1 */
        }
        return (
          <div className="success_outer_wrapper animate__animated animate__backInDown animate__delay-2s">
            <div className="success_message_of_sessions">
              <h2>Hurray!</h2>
              <h4>10 weeks in a row! You’re a 10/10! </h4>
            </div>
          </div>
        );
      } else if (no_of_sessions == 10 && isVisible == true && calenderdata) {
        {
          /* if no of session is 1 */
        }
        return (
          <div className="success_outer_wrapper animate__animated animate__backInDown animate__delay-2s">
            <div className="success_message_of_sessions">
              <h2>Hurray!</h2>
              <h4>You’ve done 10!! Let’s do it again!!</h4>
            </div>
          </div>
        );
      } else if (no_of_sessions == 8 && isVisible == true && calenderdata) {
        {
          /* if no of session is 1 */
        }
        return (
          <div className="success_outer_wrapper animate__animated animate__backInDown animate__delay-2s">
            <div className="success_message_of_sessions">
              <h2>Hurray!</h2>
              <h4>Every effort helps make a difference! </h4>
            </div>
          </div>
        );
      } else if (no_of_streaks == 8 && isVisible == true && calenderdata) {
        {
          /* if no of session is 1 */
        }
        return (
          <div className="success_outer_wrapper animate__animated animate__backInDown animate__delay-2s">
            <div className="success_message_of_sessions">
              <h2>Hurray!</h2>
              <h4>
                Your consistency is key- we appreciate all of your efforts!{" "}
              </h4>
            </div>
          </div>
        );
      } else if (no_of_streaks == 5 && isVisible == true && calenderdata) {
        {
          /* if no of session is 1 */
        }
        return (
          <div className="success_outer_wrapper animate__animated animate__backInDown animate__delay-2s">
            <div className="success_message_of_sessions">
              <h2>Hurray!</h2>
              <h4>1/10th of the way done! Keep it up! </h4>
            </div>
          </div>
        );
      } else if (
        secondweeksstatus == true &&
        isVisible == true &&
        calenderdata
      ) {
        {
          /* if no of session is 1 */
        }
        return (
          <div className="success_outer_wrapper animate__animated animate__backInDown animate__delay-2s">
            <div className="success_message_of_sessions">
              <h2>Wow!</h2>
              <h4>Two in a row! Way to Go!!</h4>
            </div>
          </div>
        );
      } else if (no_of_sessions == 1 && isVisible == true && calenderdata) {
        {
          /* if no of session is 1 */
        }
        return (
          <div className="success_outer_wrapper animate__animated animate__backInDown animate__delay-2s">
            <div className="success_message_of_sessions">
              <h2>Congratulations!</h2>
              <h4>One down, just over 50 to go! Keep up the great work!</h4>
            </div>
          </div>
        );
      } else if (no_of_sessions == 0 && isVisible == true && calenderdata) {
        {
          /* if no of session is 0 */
        }
        return (
          <div className="success_outer_wrapper animate__animated animate__backInDown animate__delay-2s">
            <div className="success_message_of_sessions">
              <h2>Hurray!</h2>
              <h4>
                Thanks for pitching in your voice so we can get closer to a
                digital biomarker!{" "}
              </h4>
            </div>
          </div>
        );
      }
    }
  };
  return (
    <>
      <div style={{ marginTop: 30 }} className="calender-outer">
        {rendermessagedata()}
        {(no_of_sessions == 0 ||
          no_of_sessions == 1 ||
          secondweeksstatus == true ||
          no_of_streaks == 8 ||
          no_of_streaks == 5 ||
          no_of_streaks == 10 ||
          no_of_streaks == 13 ||
          no_of_streaks == 2 ||
          no_of_sessions == 8 ||
          no_of_sessions == 10 ||
          no_of_sessions == 13 ||
          no_of_sessions == 16 ||
          no_of_sessions == 20 ||
          no_of_sessions == 25 ||
          no_of_sessions == 26 ||
          no_of_sessions == 30 ||
          no_of_sessions == 33 ||
          no_of_sessions == 37 ||
          no_of_sessions == 39 ||
          no_of_sessions == 40 ||
          no_of_sessions == 45 ||
          no_of_sessions == 50 ||
          no_of_sessions == 52 ||
          missingNumber > 10 ||
          (missingNumber >= 5 && missingNumber <= 10) ||
          (missingNumber >= 1 && missingNumber <= 5)) &&
          isVisible == true &&
          calenderdata && (
            <Confetti
              width={window.innerWidth}
              height={window.innerHeight}
              numberOfPieces={no_of_pieces}
              run={run_status}
              recycle={true}
              tweenDuration={8000}
            />
          )}
        <div className="row">
          <div className="col-lg-7">
            <div className="d-block d-sm-block d-md-block d-lg-none d-xl-none">
              {renderSessionData()}
            </div>
            {calenderdata.length > 0 && (
              <SessionCalender data={calenderdata} clientCode={clientCode} />
            )}
            {calenderdata.length == 0 && (
              <p
                style={{
                  fontSize: 20,
                  textAlign: "center",
                  paddingTop: 78,
                }}
              >
                Loading...
              </p>
            )}
          </div>
          <div className="col-lg-5">
            {renderStateData()}
            <div className="d-none d-sm-none d-md-none d-lg-block d-xl-block">
              {renderSessionData()}
            </div>
            {renderWeeksCompleted()}
          </div>
        </div>
      </div>
    </>
  );
};

export default SessionDetails;
